import React, { useState } from "react";
import * as styles from "./transportOptions.module.scss";
import { StaticImage } from "gatsby-plugin-image";

const transportationList1 = [
  {
    title: "Eigenanlieferung",
    image: props => (
      <StaticImage
        src={"../../images/cart_icon1.png"}
        alt="cartIcon1"
        className={styles.image}
      />
    ),
    alt: "cartIcon1",
  },
  {
    title: "Paketversand",
    image: props => (
      <StaticImage
        src={"../../images/box_icon1.png"}
        alt="boxIcon1"
        className={styles.image}
      />
    ),
    alt: "boxIcon1",
  },
  {
    title: "Abholung auf Palette",
    image: props => (
      <StaticImage
        src={"../../images/multi_box_icon1.png"}
        alt="multiBoxIcon1"
        className={styles.image}
      />
    ),
    alt: "multiBoxIcon1",
  },
];

const transportationList2 = [
  {
    title: "Abholung mit Container",
    image: props => (
      <StaticImage
        src={"../../images/truck_icon1.png"}
        alt="truckIcon1"
        className={styles.image}
      />
    ),
    alt: "truckIcon1",
  },
  {
    title: "Abholung mit Big Bag",
    image: props => (
      <StaticImage
        src={"../../images/bag_icon1.png"}
        alt="bagIcon1"
        className={styles.image}
      />
    ),
    alt: "bagIcon1",
  },
  {
    title: "Abholung mit Greifer LKW",
    image: props => (
      <StaticImage
        src={"../../images/hoist_icon1.png"}
        alt="hoistIcon1"
        className={styles.image}
      />
    ),
    alt: "hoistIcon1",
  },
];

const transportDetailed = [
  {
    title: "Eigenanlieferung",
    image: props => (
      <StaticImage
        src={"../../images/cart_icon1.png"}
        alt="cartIcon1"
        className={styles.icon}
      />
    ),
    alt: "cartIcon1",
    description:
      "Liefern Sie Ihr Altmetall bei einer Anlieferstelle in Ihrer Region selbst an. Wählen Sie ganz einfach aus den Anlieferstellen aus. Für weitere Anlieferstellen kontaktieren Sie uns bitte - unser Partnernetzwerk wächst ständig. Sie erhalten die genaue Anlieferadresse und die Öffnungszeiten nach Verkaufsabschluss per E-Mail zugeschickt. Bitte beachten Sie:",
    strongText: "Eigenanlieferungen sind erst ab €100,- Materialwert möglich.",
  },
  {
    title: "Paketversand",
    image: props => (
      <StaticImage
        src={"../../images/box_icon1.png"}
        alt="boxIcon1"
        className={styles.icon}
      />
    ),
    alt: "boxIcon1",
    description:
      "Schicken Sie uns kleinere Mengen an Altmetall einfach per Paket. Pro Paket (max. 31,5 kg) werden für Österreich und für Deutschland €6,00 mit dem Verkaufserlös verrechnet. Sie erstellen eine frankierte Versandmarke zum Ausdrucken nach Verkaufsabschluss per E-Mail.Drucken Sie die Versandmarke NICHT mehrfach aus. Jede Versandmarke kann nur einmal verwendet werden - jede weitere muss neu erstellt werden. Kleben Sie die Versandmarke auf Ihr Paket und geben Sie Ihr Paket bei der Post oder DHL Station ab. Mehr als ein Paket? Kein Problem. Generieren Sie einfach soviele Marken, wie Sie benögen.",
  },
  {
    title: "Abholung auf Palette",
    image: props => (
      <StaticImage
        src={"../../images/multi_box_icon1.png"}
        alt="multiBoxIcon1"
        className={styles.icon}
      />
    ),
    alt: "multiBoxIcon1",
    description:
      "Bei der Abholung auf Paletten halten Sie Ihr Material transportsicher auf Paletten bereit und stellen sicher, dass die Zufahrt mit dem LKW möglich ist. Falls Sie Einwegpaletten haben, die deutlich größer als Europaletten (1,20x0,80m) sind, informieren Sie uns bitte darüber im Mitteilungsfeld beim Verkaufsabschluss. Falls Paletten getauscht werden sollen, bieten wir diesen Service nur bei Europaletten an. Der Preis dieser Abholung wird automatisch auf Basis der Anzahl an Paletten, dem Gewicht und dem Standort berechnet.",
  },
  {
    title: "Abholung per Container",
    image: props => (
      <StaticImage
        src={"../../images/truck_icon1.png"}
        alt="truckIcon1"
        className={styles.icon}
      />
    ),
    alt: "truckIcon1",
    description:
      "Bei der Abholung per Container stellen wir Ihnen Container bzw. Mulden in verschiedenen Größen und Ausführungen (mit/ohne Deckel, verschließbar) zu. Sie beladen eigenständig und entscheiden, wann wir abholen sollen. Nach Verkaufsabschluss meldet sich ein Mitarbeiter bei Ihnen, um mit Ihnen den genauen Container-Typ zu vereinbaren. Bitte berücksichtigen, dass wir nicht an jedem Standort alle Container-Typen anbieten können.",
    strongText:
      "Sie brauchen Mulden oder Container für regelmäßige Abholungen? Kein Problem. Sprechen Sie mit einem Vertriebsmitarbeiter. ",
  },
  {
    title: "Abholung mit Big Bag",
    image: props => (
      <StaticImage
        src={"../../images/bag_icon1.png"}
        alt="bagIcon1"
        className={styles.icon}
      />
    ),
    alt: "bagIcon1",
    description:
      'Bei der Abholung mit Big Bags senden wir Ihnen Big Bags per Paket vorab zu. Für die Abholung (im Preis inkludiert) müssen Big Bags transportsicher auf Paletten bereitgestellt werden. Falls Paletten getauscht werden sollen, bieten wir diesen Service nur bei Europaletten an. Sollten Sie bereits Big Bags haben, wählen Sie bitte die Abholoption "Abholung auf Paletten" aus. Der Preis dieser Abholung berechnet sich aus der Anzahl an Big Bags, dem Gewicht und dem Standort berechnet.',
  },
  {
    title: "Abholung mit Greifer LKW",
    image: props => (
      <StaticImage
        src={"../../images/hoist_icon1.png"}
        alt="hoistIcon1"
        className={styles.icon}
      />
    ),
    alt: "hoistIcon1",
    description:
      "Nachdem das Material bei uns angekommen ist und eine Qualitätskontrolle durchgeführt wurde, erhalten Sie Ihr Geld innerhalb von 48 Stunden per Überweisung oder Paypal. Barauszahlung ist aus organisatorischen Gründen nicht möglich!",
  },
];

const TransportOptions = () => {
  const [showDetails, setShowDetails] = useState(false);
  return (
    <div className={styles.transportSection} id="transportoptionen-wrapper">
      <div className={`${styles.container} ${!showDetails && styles.show}`}>
        <h2 className={styles.titleSection}>Transportoptionen</h2>
        <div className={styles.wrapper}>
          <div className={styles.linkWrapperTop}>
            {transportationList1.map(item => (
              <div
                className={styles.scroller}
                onClick={() => setShowDetails(true)}
              >
                <item.image />
                <span className={styles.title}>{item.title}</span>
              </div>
            ))}
          </div>
          <div className={styles.linkWrapperTop}>
            {transportationList2.map(item => (
              <div
                className={styles.scroller}
                onClick={() => setShowDetails(true)}
              >
                <item.image />

                <span className={styles.title}>{item.title}</span>
              </div>
            ))}
          </div>
        </div>
        <a
          className={styles.squareOrangebutton}
          onClick={() => setShowDetails(true)}
        >
          Mehr Informationen
        </a>
      </div>
      <div
        className={`${styles.howDoesItWorks} ${showDetails && styles.show}`}
        id="transportoptionen-detail-wrapper-ablauf"
      >
        <h2 className={styles.textCenter}>Transportoptionen</h2>
        <h3 className={styles.details}>Detail-Ansicht</h3>
        <div className={styles.detailWrapperAblauf}>
          {transportDetailed.map(item => (
            <div className={styles.infoWrapper}>
              <div className={styles.infoWrapperGroup}>
                <div className={styles.infoImg}>
                  <item.image />
                </div>
                <div className={styles.infoText}>
                  <span className={styles.detailsTitle}>{item.title}</span>
                  <p className={styles.description}>
                    {item.description}
                    {item.strongText && <strong>{item.strongText}</strong>}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <a
          href="#transportoptionen-wrapper"
          className={styles.squareOrangebutton}
          id="hide-transportoptionen-detail"
          onClick={() => setShowDetails(false)}
        >
          Weniger Informationen
        </a>
      </div>
    </div>
  );
};
export default TransportOptions;
