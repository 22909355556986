import React, { useContext, useState } from "react";
import Helmet from "react-helmet";

import styles from "./index.module.scss";
import { I18nextProvider } from "react-i18next";
import DomainContext from "../../context/DomainContext";
import i18n from "../../i18n/i18n";
import NavMenu from "../../components/nav-menu/NavMenu";
import HowItWorksDescription from "../../components/how-it-works-description/HowItWorksDescription";
import YoutubeVideos from "../../components/youtube-videos/YoutubeVideos";
import TransportOptions from "../../components/transport-options/TransportOptions";
import Footer from "../../components/main-page/sections/footer/Footer";
import GroupLegal from "../../components/group-legal/GroupLegal";
import FAQ from "../../components/faq/FAQ";
import SEOComponent from "../../components/Shared/Seo";

export default () => {
  const [homeUrl, setHomeUrl] = useState("");
  const domain = useContext(DomainContext);

  return (
    <I18nextProvider i18n={i18n} defaultNS={"default"}>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="note" content="environment=development" />
        <script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyBfDwQK3D11mRl5zact_Hk-QctWpfqEkKk&libraries=places"></script>
      </Helmet>
      <SEOComponent page="ablauf" />
      <NavMenu />
      <div className={styles.breadcrumb}>
        <a href={homeUrl}>Home</a>
        <span className={styles.separator}>/</span>
        <span> Ablauf</span>
      </div>
      <HowItWorksDescription />
      <YoutubeVideos />
      <TransportOptions />
      <FAQ />
      <Footer domain={domain} />
      <GroupLegal />
    </I18nextProvider>
  );
};
