import React from "react";
import * as styles from "./youtubeVideos.module.scss";
import { StaticImage } from "gatsby-plugin-image";

import hqdefault from "../../images/hqdefault.jpg";
import digitalizing from "../../images/digitalizing.jpg";
import recycle from "../../images/recycle.jpg";
import dealers from "../../images/dealers.jpg";
import sellScrap from "../../images/sellScrap.jpg";
import carRally from "../../images/carRally.jpg";

const row1Videos = [
  {
    title: "Schrott24 - Digitalisierung des Altmetallmarkts",
    link: "https://www.youtube.com/watch?v=MY-CROlgIu8",
    image: () => (
      <StaticImage
        src={"../../images/hqdefault.jpg"}
        alt="hqdefault"
        className={styles.image}
      />
    ),
    alt: "hqdefault",
  },
  {
    title: "We are digitalising the scrap metal market!",
    link: "https://www.youtube.com/watch?v=uaJTM9wwr1s",
    image: () => (
      <StaticImage
        src={"../../images/digitalizing.jpg"}
        alt="digitalizing"
        className={styles.image}
      />
    ),
    alt: "digitalizing",
  },
  {
    title: "Schrott24 recycelt Teile von AKW Mülheim-Kärlich",
    link: "https://www.youtube.com/watch?v=bFPBkYwgGog",
    image: () => (
      <StaticImage
        src={"../../images/recycle.jpg"}
        alt="recycle"
        className={styles.image}
      />
    ),
    alt: "recycle",
  },
];

const row2Videos = [
  {
    title: "Partner Webshop für Altmetallhändler - Schrott24",
    link: "https://www.youtube.com/watch?v=PTzqbtzTW1s",
    image: () => (
      <StaticImage
        src={"../../images/dealers.jpg"}
        alt="dealers"
        className={styles.image}
      />
    ),
    alt: "dealers",
  },
  {
    title:
      "Der digitale Schrotthändler - Altmetall online verkaufen - Schrott24",
    link: "https://www.youtube.com/watch?v=76CdWnK6Q60",
    image: () => (
      <StaticImage
        src={"../../images/sellScrap.jpg"}
        alt="sellScrap"
        className={styles.image}
      />
    ),
    alt: "sellScrap",
  },
  {
    title: "Schrottauto Rally - Schrott24",
    link: "https://www.youtube.com/watch?v=rIvBqS4_t3Y",
    image: () => (
      <StaticImage
        src={"../../images/carRally.jpg"}
        alt="carRally"
        className={styles.image}
      />
    ),
    alt: "carRally",
  },
];

const YoutubeVideos = () => (
  <div className={styles.videoYoutubePostWrapper}>
    <h2 className={styles.titleSection}>Videos</h2>
    <div className={styles.youtubeComponent}>
      <div className={styles.rowOfItems}>
        {row1Videos.map(item => (
          <a href={item.link} key={item.alt}>
            <div className={styles.singleItemWrapper}>
              <div className={styles.videoTitle}>{item.title}</div>
              <div className={styles.videoImage}>
                <item.image />
              </div>
            </div>
          </a>
        ))}
      </div>
      <div className={styles.rowOfItems}>
        {row2Videos.map(item => (
          <a href={item.link} key={item.alt}>
            <div className={styles.singleItemWrapper}>
              <div className={styles.videoTitle}>{item.title}</div>
              <div className={styles.videoImage}>
                <item.image />
              </div>
            </div>
          </a>
        ))}
      </div>
    </div>
  </div>
);
export default YoutubeVideos;
