import React, { useState } from "react";
import * as styles from "./howItWorksDescription.module.scss";

import { StaticImage } from "gatsby-plugin-image";

const HowItWorksDescription = () => {
  const [showDescription, setShowDescription] = useState(false);
  return (
    <div className={styles.howDoesItWorksSection}>
      <div
        className={`${
          styles.howDoesItWorksSectionWrapperAblauf
        } ${!showDescription && styles.show}`}
      >
        <div className={styles.containerHowDoesItWorksWrapperAblauf}>
          <h1 className={styles.standardTitle}>So funktioniert Schrott24</h1>
          <h3 className={styles.subTitle}>Hilfe &amp; Services</h3>
          <div className={styles.stepWrapper}>
            <div className={styles.groupOfNumbers}>
              <StaticImage
                className={styles.stepImage}
                src={"../../images/stepBullet1.svg"}
                alt="step1"
              />
              <div className={styles.greyLineSeparator}></div>
              <StaticImage
                className={styles.stepImage}
                src={"../../images/stepBullet2.svg"}
                alt="step2"
              />
              <div className={styles.greyLineSeparator}></div>
              <StaticImage
                className={styles.stepImage}
                src={"../../images/stepBullet3.svg"}
                alt="step3"
              />
            </div>
          </div>
          <div className={styles.linkWrapperTop}>
            <div className={styles.linkAtTop}>
              <div
                className={styles.wrapper}
                onClick={() => setShowDescription(true)}
              >
                <a href="#info1" className={styles.link}>
                  <StaticImage
                    className={styles.icon}
                    src={"../../images/schrott_wahlen.png"}
                    alt="schrott wahlen"
                  />
                  <span className={styles.title}>Schrott wählen</span>
                  <p className={styles.description}>
                    Gewicht schätzen
                    <br />
                    Ankaufspreis berechnen
                  </p>
                </a>
              </div>
              <div
                className={styles.wrapper}
                onClick={() => setShowDescription(true)}
              >
                <a href="#info2" className={styles.link}>
                  <StaticImage
                    className={styles.icon}
                    src={"../../images/transport_wahlen.png"}
                    alt="transport wahlen"
                  />
                  <span className={styles.title}>Transport wählen</span>
                  <p className={styles.description}>
                    Paketversand
                    <br />
                    Abholung
                    <br />
                    Eigenanlieferung
                  </p>
                </a>
              </div>
              <div
                className={styles.wrapper}
                onClick={() => setShowDescription(true)}
              >
                <a href="#info3" className={styles.link}>
                  <StaticImage
                    className={styles.icon}
                    src={"../../images/geld.png"}
                    alt="geld"
                  />
                  <span className={styles.title}>Geld</span>
                  <p className={styles.description}>
                    Überweisung
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
        <a
          className={styles.squareOrangebutton}
          href="#how-does-it-works-detail-wrapper-ablauf"
          onClick={() => setShowDescription(true)}
        >
          Mehr informationen
        </a>
      </div>
      <div
        className={`${styles.containerDefault} ${showDescription &&
          styles.show}`}
        id="how-does-it-works-detail-wrapper-ablauf"
      >
        <h2 className={styles.blueTitle}>So funktioniert's</h2>
        <h3 className={styles.textCenter}>Detail-Ansicht</h3>
        <div className={styles.howDoesWrapperAblauf}>
          <div className={styles.infoWrapper} id="info1">
            <div className={styles.infoWrapperGroup}>
              <div className={styles.infoImg}>
                <StaticImage
                  className={styles.img}
                  src={"../../images/schrott_wahlen.png"}
                  alt=""
                />
              </div>
              <div className={styles.infoText}>
                <span>Schrott wählen </span>Wählen Sie Ihr Material aus unserer
                Kategorie Seite aus!
                <br />
                <br />
                Das Gewicht soll ungefähr geschätzt werden. Wenn das Material
                bei uns angekommen ist, wird eine Materialprüfung durchgeführt.
                Im Zuge dieser wird das genaue Gewicht mit geeichten Waagen
                ermittelt.
                <br />
                <br />
                Nicht die richtige Menge angegeben? Abweichungen vom
                tatsächlichen Gewicht sind kein Problem und werden in weiterer
                Folge bei der Materialprüfung angeglichen und Ihr Erlös wird
                angepasst.
                <br />
                <br />
                Finden Sie Ihr gewünschtes Produkt nicht? Bitte schreiben Sie
                uns eine Nachricht, eventuell können wir. Ihnen ein
                individuelles Angebot für Ihr Material erstellen.
              </div>
            </div>
          </div>
          <div className={styles.infoWrapper} id="info2">
            <div className={styles.infoWrapperGroup}>
              <div className={styles.infoImg}>
                <StaticImage
                  className={styles.img}
                  src={"../../images/transport_wahlen.png"}
                  alt=""
                />
              </div>
              <div className={styles.infoText}>
                <span>Transport wählen </span>Sie können zwischen 3
                Transportarten wählen:&nbsp;
                <br />
                Paketversand
                <br />
                Abholung (Paletten,
                <br />
                Container, Big Bag, Greifer-LKW )<br />
                Eigenanlieferung
                <br />
              </div>
            </div>
          </div>
          <div className={styles.infoWrapper} id="info3">
            <div className={styles.infoWrapperGroup}>
              <div className={styles.infoImg}>
                <StaticImage
                  className={styles.img}
                  src={"../../images/geld.png"}
                  alt=""
                />
              </div>
              <div className={styles.infoText}>
                <span>Geld&nbsp;verdienen </span>Nachdem das Material bei uns angekommen 
                ist und eine Qualitätskontrolle durchgeführt wurde erhalten Sie Ihr Geld 
                innerhalb von 48 Stunden per Überweisung. Barauszahlung ist aus 
                organisatorischen Gründen nicht möglich!
              </div>
            </div>
          </div>
        </div>
        <a
          href="#how-does-it-works-wrapper-ablauf"
          className={styles.squareOrangebutton}
          id="hide-how-does-it-works-detail"
          onClick={() => setShowDescription(false)}
        >
          Weniger Informationen
        </a>
      </div>
      <div
        className={styles.sectionBackgroundContainer}
        id="section-background-container"
      >
        <div className={styles.blueBackgroundTriangle}></div>
        <div className={styles.blueBackgroundSquare}></div>
      </div>
    </div>
  );
};

export default HowItWorksDescription;
