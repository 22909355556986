import React from "react";
import * as styles from "./faq.module.scss";

export const faqList = [
  {
    id: "1",
    question: "Bis wann muss ich meinen Verkauf anliefern?",
    answer: (
      <p>
        Für einen optimalen Ablauf bitte wir Sie&nbsp;
        <strong>
          innerhalb der nächsten zwei Werktage (ab dem Tag nach dem Verkauf)
        </strong>
        &nbsp;anzuliefern.&nbsp;
        <br />
        Sie haben maximal 14 Tage Zeit, um Ihre Anlieferung durchzuführen.
        Sollten Sie einen Verkauf durchgeführt haben und das Material nicht
        innerhalb von 14 Tagen bei der Annahmestelle eingegangen sein, so wird
        der Verkauf storniert. Bei einer Stornierung fallen Stornogebühren laut
        den&nbsp;
        <a className={styles.colorOrangeMain} href="/faq/agb/">
          Allgemeinen Geschäftsbedingungen
        </a>
        &nbsp;an.
      </p>
    ),
  },
  {
    id: "2",
    question: "Muss ich alles genau wiegen?",
    answer: (
      <p>
        Es genügt, wenn Sie die Menge schätzen. Das genaue Gewicht und die
        Qualität wird beim Eintreffen des Materials auf geeichten Waagen
        ermittelt. Sie erhalten immer genau das vergütet, was Sie uns anliefern,
        auch wenn Sie online weniger angegeben haben.
      </p>
    ),
  },
  {
    id: "3",
    question: "Wieso erhalte ich die Adresse erst nach Verkaufsabschluss?",
    answer: (
      <p>
        Für einen optimalen Ablauf bitte wir Sie&nbsp;
        <strong>
          innerhalb der nächsten zwei Werktage (ab dem Tag nach dem Verkauf)
        </strong>
        &nbsp;anzuliefern.&nbsp;
        <br />
        Sie haben maximal 14 Tage Zeit, um Ihre Anlieferung durchzuführen.
        Sollten Sie einen Verkauf durchgeführt haben und das Material nicht
        innerhalb von 14 Tagen bei der Annahmestelle eingegangen sein, so wird
        der Verkauf storniert. Bei einer Stornierung fallen Stornogebühren laut
        den&nbsp;
        <a className={styles.colorOrangeMain} href="/faq/agb/">
          Allgemeinen Geschäftsbedingungen
        </a>
        &nbsp;an.
      </p>
    ),
  },
  {
    id: "4",
    question: "Wo kann ich anliefern?",
    answer: (
      <p>
        Unsere Anlieferstellen bei ausgewählten und zertifizierten Partnern
        finden Sie in Ihrer Nähe.
        <br />
        Als Alternative können Sie Ihr Altmetall auch einfach abholen lassen,
        oder per Paket schicken - ganz unkompliziert und komfortabel!
        <br />
        <strong>Hinweis:</strong>&nbsp;Genaue Anlieferadresse und Öffnungszeiten
        erhalten Sie nach Verkaufsabschluss automatisch per E-Mail.
      </p>
    ),
  },
  {
    id: "5",
    question: "Ich habe mehr als 31,5 kg Material?",
    answer: (
      <p>
        Kein Problem – gerne können Sie so viele Versandmarken, wie Sie
        benötigen erstellen. Drucken Sie die Versandmarke NICHT mehrfach aus.
        Jede Versandmarke kann nur einmal verwendet werden - jede weitere muss
        neu erstellt werden.
        <br />
        <strong>Hinweis:</strong>&nbsp;Pro (genutzer) Versandmarke werden
        Ihnen&nbsp;<strong>€ 5,-</strong>&nbsp;von Ihrem Erlös abgezogen.
        <br />
        Für ungenutze Versandmarken fallen keine Kosten an!
        <br />
        Alternativ können Sie Ihr Material auch bei uns anliefern oder das
        Material von uns per Spedition abholen lassen.
      </p>
    ),
  },
  {
    id: "6",
    question: "Können Sie mein Material auch abholen?",
    answer: (
      <p>
        Selbstverständlich können wir Ihnen eine Abholung anbieten. Wir
        benötigen dafür folgende zusätzlichen Informationen, um Ihnen ein
        entsprechendes Angebot zu unterbreiten:
        <br />
        <br />
        <strong>Adresse</strong>&nbsp;– Wo soll das Material abgeholt werden?
        <br />
        <strong>Gewicht</strong>&nbsp;– Wie schwer ist ihr Material?
        <br />
        <strong>Maße</strong>&nbsp;– Wie groß sind die verschiedenen
        Materialien?(Senden Sie gerne zusätzlich Fotos)
        <br />
        <strong>Verpackung</strong>&nbsp;– Wie ist das Material verpackt
        (Kisten, Paletten etc.)?
      </p>
    ),
  },
  {
    id: "7",
    question:
      "Kaufen Sie auch andere Produkte als auf der Website anzufinden sind?",
    answer: (
      <p>
        Wir überarbeiten und erweitern unser Ankaufssortiment laufend. Sollten
        Sie ein Produkt nicht finden, schreiben Sie uns eine Nachricht ! Wir
        können Ihnen dann eventuell ein individuelles Angebot machen oder
        unserer Produktsortiment erweitern. Drucker und Tastaturen finden sich
        (zur Zeit) ebenfalls nicht in unserem Ankaufssortiment. Größe, Gewicht
        und relativer Preis sind bei diesen Materialien ausschlaggebend dafür,
        dass sich der Versand hier nicht lohnt. Ganze Monitore, Lithium Ionen
        Batterien etc. finden sich ebenfalls nicht in unserem Ankaufsortiment.
        Da die Entsorgung dieser Güter sehr teuer ist, werden solche Produkte
        zum Teil gratis, zum Teil nur kostenpflichtig weiterverarbeitet.
      </p>
    ),
  },
  {
    id: "8",
    question: "Kann ich mein Material selbst anliefern?",
    answer: (
      <p>
        Gerne können Sie Ihr Material auch bei einer unserer Annahmestellen
        anliefern. Wir haben in allen Bundesländern Partner und sind daher immer
        in Ihrer Nähe! Wählen Sie bitte beim Online-Verkauf als Versandart
        "Eigenanlieferung" aus. Wir schicken Ihnen danach ein Mail mit
        Anlieferadresse und Öffnungzeiten unseres Partners!
        <br />
        <strong>Hinweis:</strong>&nbsp;Vergessen Sie nicht Ihre
        Verkaufsbestätigung auszudrucken sowie einen Lichtbildausweis
        mitzubringen. Nur dann erhalten Sie die Online-Preise von Schrott24.
        Ansonsten gelten die tagesaktuellen Preise des jeweiligen Partners bzw.
        Schrotthändlers.
      </p>
    ),
  },
  {
    id: "9",
    question: "Kann ich auch Großmengen ab 5 Tonnen verkaufen?",
    answer: (
      <p>
        Wir kaufen grundsätzlich Material in allen Größenordnungen an. Wenn Sie
        auf unserer Website für Ihre Bedürftnisse nicht die richtige Lösung
        finden, dann zögern Sie nicht und kontaktieren Sie uns ganz einfach über
        unser <a href="https://pro.schrott24.de/">Anfrage-Formular</a>. Ein
        Mitarbeiter wird sich umgehend mit Ihnen in Verbindung setzen.
      </p>
    ),
  },
  {
    id: "10",
    question: "Wie erkenne ich, welches Material ich zu Hause habe?",
    answer: (
      <p>
        Viele der angebotenen Materialien sind schwer zu unterscheiden. Auch
        unsere Experten greifen auf professionelle Analysegeräte zurück, um
        diese eindeutig zu identifizieren. Sollten Sie sich also nicht sicher
        sein, was für ein Material Sie bei sich haben, schreiben Sie uns einfach
        ein Mail und fügen, falls vorhanden, ein Foto Ihres Schrotts bei. Wir
        helfen Ihnen – sofern ferndiagnostisch möglich – gerne weiter.
        <br />
        <br />
        <strong>
          Sollten Sie sich bei der Zuordnung geirrt haben, ist auch das kein
          Problem.
        </strong>
        &nbsp;Im Zuge unserer Materialprüfung wird jedes eingehende Material
        untersucht. Sollte ein anderes Material ankommen als Sie beim Verkauf
        ausgewählt haben, wird eine dokumentierte Reklamation in die Wege
        geleitet. In diesem Fall nehmen wir mit Ihnen Kontakt auf und suchen
        gemeinsam nach einer Lösung, um den Verkauf abzuschließen.
        <br />
        <br />
        Hier finden Sie noch einige nützliche&nbsp;<strong>Tipps</strong>
        ,&nbsp;die Ihnen bei der Zuordnung helfen:
        <br />
        <br />
        <strong>Farbe:</strong>&nbsp;Ein erster Anhaltspunkt, um Buntmetalle
        auseinander zu halten, ist die Farbe. Grundsätzlich unterscheiden sich
        Aluminium (silbrig-weiß), Kupfer (rötlich), Messing (je nach
        Legierungsanteilen bräunlich bis golden bis gelblich), Blei, Zink, Zinn
        in ihren Farben. Bei Verwitterung der Materialien sind Eisen, Aluminium,
        Blei und Zink sowie viele andere Sorten leider nicht mehr eindeutig
        auseinander zu halten. Achtung: Manche Materialien sind mit mit einer
        sehr dünnen Schicht Chrom überzogen, damit es schön silbrig glänzt. Hier
        kann man das tatsächliche Material nicht mehr mit dem Auge erkennen
        (besonders häufig ist dies bei Armaturen aus Messing der Fall).
        <br />
        <br />
        <strong>Gewicht:</strong>&nbsp;Ein weiterer Anhaltspunkt, um Buntmetalle
        zu unterscheiden, ist das relative Gewicht des Materials. Eisen und
        Nirosta sind spürbar schwerer als Aluminium, Blei und Hartmetall noch
        schwerer. So kann man etwa Hartmetallbohrer von Eisenbohrern oder
        Aluminiumpfannen von Eisenpfannen recht zuverlässig unterscheiden.
        <br />
        <br />
        <strong>Magnetismus:</strong>&nbsp;Buntmetalle sind in der Regel nicht
        magnetisch. So können Eisenteile von Aluminiumteilen einfach
        unterschieden werden. Edelstahl (Nirosta) ist ebenfalls nicht (in
        seltenen Fällen ganz leicht) magnetisch.
      </p>
    ),
  },
  {
    id: "11",
    question: "Bieten Sie auch einen Service für regelmäßige Schrottmengen?",
    answer: (
      <p>
        Wenn Sie einen regelmäßigen Schrotttransport planen möchten, sind Sie
        bei uns richtig. Schicken Sie uns ganz einfach eine{" "}
        <a className={styles.colorOrangeMain} href="https://pro.schrott24.de/">
          Anfrage
        </a>
        . Wir melden uns sehr schnell bei Ihnen zurück.
      </p>
    ),
  },
  {
    id: "12",
    question:
      "Warum verändert sich der Preis während des Verkaufsvorgangs nochmals?",
    answer: (
      <p>
        Die Preise variieren abhängig von gewählter Transportart und Standort,
        weshalb sich finale Preise von den anfänglich angezeigten Preisen
        unterscheiden können.
      </p>
    ),
  },
  {
    id: "13",
    question: "Warum ist der finale Preis nicht der angezeigte Preis?",
    answer: (
      <p>
        Die Preise variieren abhängig von gewählter Transportart und Standort,
        weshalb sich finale Preise von den anfänglich angezeigten Preisen
        unterscheiden können.
      </p>
    ),
  },
  {
    id: "14",
    question:
      "Warum kann ich Eigenanlieferung nicht als Transport-Option nutzen?",
    answer: (
      <p>
        Bei der Option "Eigenanlieferung" ist eine Mindestmenge notwendig,
        weshalb wir für Kleinmengen die Option "Paketversand" empfehlen. Bei zu
        geringem Materialwert, lohnt sich der organisatorische Aufwand leider
        nicht.
      </p>
    ),
  },
  {
    id: "15",
    question: "Ist eine Barauszahlung möglich?",
    answer: (
      <p>
        Bei der Option "Eigenanlieferung" ist eine Mindestmenge notwendig,
        weshalb wir für Kleinmengen die Option "Paketversand" empfehlen. Bei zu
        geringem Materialwert, lohnt sich der organisatorische Aufwand leider
        nicht.
      </p>
    ),
  },
  {
    id: "16",
    question:
      "Wieso kommt es vor, dass ich nicht immer beim selben Standort anliefern kann?",
    answer: (
      <p>
        Nicht jeder Standort ist für alle Schrottsorten spezialisiert. Deshalb
        kommt es vor, dass bei verschiedenen Verkäufen andere Standorte Ihr
        Material entgegen nehmen.
      </p>
    ),
  },
  {
    id: "17",
    question: "Wie funktioniert die Abholung mit Big Bags?",
    answer: (
      <p>
        Sie können bei uns Big Bags für € 5 pro Stück erwerben, welche wir
        anschließend an Sie per Post versenden (exkl. Versandkosten). Die
        befüllten Big Bags werden dann bei Ihnen abgeholt.
      </p>
    ),
  },
  {
    id: "18",
    question: "Muss ich mich ausweisen?",
    answer: (
      <p>
        Bitte nehmen Sie Ihren Ausweis mit, wenn Sie Ihr Material selbst
        anliefern.
      </p>
    ),
  },
  {
    id: "19",
    question: "Wie funktioniert der Verkauf auf Schrott24?",
    answer: (
      <ol>
        <li>
          <strong>SCHROTTSORTE WÄHLEN</strong>
          <br />
          Wählen Sie Ihr Material auf der{" "}
          <a className={styles.colorOrangeMain} href="/altmetall-ankauf/">
            Altmetallsorten-Übersicht
          </a>{" "}
          aus!
          <br />
          Finden Sie Ihr gewünschtes Produkt nicht?
          <br />
          Bitte schreiben Sie uns eine Nachricht , eventuell können wir Ihnen
          ein individuelles Angebot für Ihr Material erstellen.
          <br />
        </li>
        <br />
        <li>
          <strong>GEWICHT SCHÄTZEN</strong>
          <br />
          Das Gewicht soll ungefähr geschätzt werden. Wenn das Material bei uns
          angekommen ist, wird eine Materialprüfung durchgeführt. Im Zuge dieser
          wird das genaue Gewicht mit geeichten Waagen ermittelt.
          <br />
          Nicht die richtige Menge angegeben?
          <br />
          Abweichungen vom tatsächlichen Gewicht sind kein Problem und werden in
          weiterer Folge bei der Materialprüfung angeglichen und Ihr Erlös wird
          angepasst.
          <br />
        </li>
        <br />
        <li>
          <strong>TRANSPORT AUSWÄHLEN</strong>
          <br />
          Sie können zwischen 3 Transportarten wählen:
          <br />
          <ul>
            <li>Paketversand</li>
            <li>Abholung</li>
            <li>Eigenanlieferung</li>
          </ul>
        </li>
      </ol>
    ),
  },
  {
    id: "20",
    question: "Wie funktioniert die Abholung?",
    answer: (
      <ol>
        <li>
          <strong>ABHOLART AUSWÄHLEN</strong>
          <ul>
            <li>
              Abholung auf Palette: Verstauen Sie Ihr Material auf einer
              Palette, welche dann bei Ihnen nur noch abgeholt werden muss.
            </li>
            <li>
              Big Bag inkl. Abholung: Packen Sie Ihr Materialien in einen oder
              mehrere Big Bags, welche wir dann bei Ihnen abholen.
            </li>
            <li>
              Abholung mit LKW/Container
              <br />
              Falls Sie die Option „Abholung mit LKW/Container“ ausgewählt
              haben, wird ein individuelles Angebot für Sie erstellt. Das
              Angebot ist unverbindlich. Sie können das Angebot annehmen oder
              vom Angebot zurücktreten.
            </li>
          </ul>
        </li>
        <br />
        <li>
          <strong>QUALITÄTSKONTROLLE</strong>
          <br />
          Nachdem das Material bei uns angekommen ist, erfolgt eine
          Materialprüfung und genaue Wiegung Ihres Materials auf geeichten
          Waagen.
        </li>
        <br />
        <li>
          <strong>GELDÜBERWEISUNG</strong>
          <br />
          Sie erhalten Ihr Geld innerhalb von 48h nach der Materialprüfung per
          Überweisung oder PayPal.
        </li>
      </ol>
    ),
  },
  {
    id: "21",
    question: "Wie funktioniert der Paketversand?",
    answer: (
      <ol>
        <li>
          <strong>AUFKLEBEN DER POSTMARKE</strong>
          <br />
          Nach Verkaufsabschluss erstellen Sie eine frankierte Postmarke. Diese
          müssen Sie ausdrucken und auf Ihr Paket/Ihre Pakete kleben. Drucken
          Sie die Versandmarke NICHT mehrfach aus. Jede Versandmarke kann nur
          einmal verwendet werden - jede weitere muss neu erstellt werden.
        </li>
        <br />
        <li>
          <strong>VERSENDEN DES PAKETS/DER PAKETE</strong>
          <br />
          Das Paket/Die Pakete können nach dem Aufkleben der von uns
          zugesendeten frankierten Postmarke bei jeder Post oder DHL Station
          abgegeben werden.{" "}
          <a href="https://youtu.be/bXhGKaLjf8k">
            4 Tipps - So verpacken Sie Ihr Paket transportsicher&nbsp;
          </a>
        </li>
        <br />
        <li>
          <strong>QUALITÄTSKONTROLLE</strong>
          <br />
          Nachdem das Paket/die Pakete bei uns angekommen ist/sind, erfolgt eine
          Materialprüfung und genaue Wiegung Ihres Materials auf geeichten
          Waagen.
        </li>
        <br />
        <li>
          <strong>GELDÜBERWEISUNG</strong>
          <br />
          Sie erhalten Ihr Geld innerhalb von 48h nach der Materialprüfung per
          Überweisung oder PayPal.
        </li>
      </ol>
    ),
  },
  {
    id: "22",
    question: "Wie muss ich Pakete verpacken?",
    answer: (
      <ul>
        <li>
          Pakete können bei allen Post-Annahmestellen abgegeben werden. Sie
          müssen dort nichts bezahlen, aber{" "}
          <strong>unbedingt unsere Paketmarke verwenden</strong>. "Unfrei"
          gesendete Pakete können nicht angenommen werden.
        </li>
        <li>
          <strong>Pakete müssen TRANSPORTSICHER verpackt werden.</strong> Achten
          Sie darauf, dass während des Transports keine Risse oder Löcher im
          Paket entstehen können, durch welche Material herausfallen könnte.{" "}
          <a href="https://youtu.be/bXhGKaLjf8k">
            4 Tipps - So verpacken Sie Ihr Paket transportsicher&nbsp;
          </a>
        </li>
        <li>
          <strong>Altmetall muss immer sortenrein sein.</strong> Mehrere Sorten
          können Sie auch in einem Paket z.B. durch Pappe voneinander trennen.
          Sollten Sorten vermischt angeliefert werden, kann nur der Preis der
          schlechtesten Qualität vergütet werden.
        </li>
      </ul>
    ),
  },
 
];
