import React, { useState } from "react";
import * as styles from "./faq.module.scss";
import { faqList } from "./faqData";

const FAQ = () => {
  const [activeQuestion, setActiveQuestion] = useState("");
  const [showAll, setShowAll] = useState(false);

  const handleActiveQuestion = id => {
    if (activeQuestion === id) {
      setActiveQuestion("");
    } else {
      setActiveQuestion(id);
    }
  };

  const faq = showAll ? faqList : faqList.slice(0, 6);

  return (
    <div className={styles.faqGroupContainer} id="faqSection">
      <div className={styles.containerDefault}>
        <h2 className={styles.titleSection} id="faq">
          Häufige Fragen
        </h2>
        <div className={styles.container}>
          <div className={styles.faqContainer}>
            {faq.map(item => (
              <div className={styles.qaContainer}>
                <div
                  className={`${styles.faqQuestion} ${activeQuestion ===
                    item.id && styles.topBorder}`}
                  onClick={() => handleActiveQuestion(item.id)}
                >
                  {item.question}
                </div>
                <div
                  className={`${styles.faqAnswer} ${activeQuestion ===
                    item.id && styles.active}`}
                >
                  {item.answer}
                </div>
              </div>
            ))}
            <a
              className={styles.squareOrangebutton}
              href="#faqSection"
              id="hide-more-questions"
              onClick={() => setShowAll(!showAll)}
            >
              {showAll ? "WENIGER HÄUFIGE FRAGEN" : "MEHERE HÄUFIGE FRAGEN"}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FAQ;
